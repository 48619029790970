import resolveImage from "./resolveImage";

const resolveCaseStudy = cs => {
  const { metaDescription } = cs;
  return {
    ...cs,
    descriptor: metaDescription,
    image: resolveImage(cs.metaImage),
  };
};

export default resolveCaseStudy;
