import { useStaticQuery, graphql } from "gatsby";
import resolveCaseStudy from "@dataResolvers/resolveCaseStudy";

const query = graphql`
  query CaseStudiesQuery {
    craft {
      entries(section: "caseStudies", limit: 3) {
        ... on Craft_caseStudies_default_Entry {
          uid
          link: url
          title
          descriptor: descriptor0
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return {
    caseStudies: entries?.map(resolveCaseStudy),
  };
};

const useCaseStudiesData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useCaseStudiesData;
