import React from "react";
import clsx from "clsx";
import { CaseStudyCard } from "@molecules";
import { Container, Button } from "@atoms";
import useCaseStudiesData from "@staticQueries/CaseStudiesQuery";

const CaseStudyGrid = ({ button, className: _className, entriesOverride }) => {
  const { caseStudies } = useCaseStudiesData();
  const entries = entriesOverride.length ? entriesOverride : caseStudies;

  return (
    <section
      id="casestudies"
      className={clsx("my-10 scroll-mt-60 sm:my-20", _className)}
    >
      <Container padding className="mt-12">
        {!!entries?.length && (
          <div className="flex flex-wrap justify-center flex-gap-6">
            {entries.map(entry => {
              return (
                <div className="w-full md:w-1/2 lg:w-1/3">
                  <CaseStudyCard {...entry} />
                </div>
              );
            })}
          </div>
        )}
        {button?.url && (
          <div className="mt-12 flex justify-center">
            <Button to={button.url} color="purple" size="md">
              {button.text || "Read More"}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

CaseStudyGrid.defaultProps = {};

export default CaseStudyGrid;
